@import '@/_styles/import';
.root {
  &:global(._appearance-countdown),
  &:global(._appearance-tag) {
    display: flex;
    flex-direction: row;

    width: fit-content;
    padding: 3px 8px 3px 6px;

    font-size: 13px;
    font-weight: 500;
    line-height: 20px;

    border-radius: 3px;

    &:global(._theme-ink) {
      --promobanner-eyebrow-digit-color: #{$white};

      color: $tropical-blue-2;
      background-color: $black-violet;
    }

    &:global(._theme-seaSpray) {
      --promobanner-eyebrow-digit-color: #{$vv-red};

      color: $black-violet;
      background-color: $light-green1;
    }

    &:global(._theme-urgency) {
      --promobanner-eyebrow-digit-color: #{$vv-red};

      color: $black-violet;
      background-color: $pale-yellow-orange;
    }

    &:global(._theme-cyber) {
      --promobanner-eyebrow-digit-color: #{$white};

      color: $light-green1;
      background-color: $blue-purple;
    }
  }

  &:global(._appearance-countdown) {
    column-gap: 8px;

    &:not(:has(.countdown)) {
      display: none;
    }
  }

  &:global(._appearance-tag) {
    column-gap: 5px;
  }

  &:global(._appearance-text) {
    font-size: 13px;
    font-weight: 700;
    line-height: 16.9px;
    letter-spacing: 0.2em;
  }
}

.icon {
  padding: 0 0 1px;
}

.countdown {
  gap: 8px;

  &:global(._short),
  &:global(._unlabeled) {
    gap: 3px;

    :global(.Countdown__digit:first-child) {
      margin-right: 5px;
    }
  }

  &:global(.Countdown .Countdown__digit) {
    gap: 3px;
  }

  &:global(._short .Countdown__digit) {
    gap: 1px;
  }

  &:global(.Countdown .Countdown__label),
  &:global(.Countdown .Countdown__value) {
    font-size: inherit;
    line-height: inherit;
  }

  &:global(.Countdown .Countdown__value) {
    min-width: 17px;
    color: var(--promobanner-eyebrow-digit-color);
    text-align: center;

    &:global(._long) {
      min-width: 24px;
    }
  }

  &:global(.Countdown._compact :last-child .Countdown__label) {
    width: 25px;
  }
}
