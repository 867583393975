@use 'sass:map';
@import '@/_styles/import';

$sizes: (
  xs: 431px,
  sm: 768px,
  md: 862px,
);

@mixin layoutDown($size) {
  @container (width <= #{map.get($sizes, $size)}) {
    @content;
  }
}

.root {
  --billboard-padding-x: 40px;
  --billboard-padding-y: 0;
  --billboard-content-color: #{$white};
  --billboard-content-padding-x: 20px;
  --billboard-content-padding-y: 25px;
  --billboard-content-width: 400px;
  --billboard-content-overlay-bg: transparent;
  --billboard-content-overlay-border-width: 0 8px 0 0;
  --billboard-content-overlay-border: transparent;

  position: relative;
  border-radius: 8px;
  padding-left: var(--billboard-padding-x);
  padding-right: var(--billboard-padding-x);
  padding-bottom: var(--billboard-padding-y);
  display: flex;
  justify-content: flex-start;

  &:not(:has(.picture)) {
    background-color: $lightgrey2;
  }

  &:global(._align-right) {
    --billboard-content-overlay-border-width: 0 0 0 8px;

    justify-content: flex-end;
  }

  @include layoutDown(md) {
    --billboard-padding-x: 30px;
    --billboard-content-padding-x: 15px;
    --billboard-content-padding-y: 24.5px;
    --billboard-content-width: 300px;
  }

  @include layoutDown(sm) {
    --billboard-content-overlay-border-width: 8px 0 0;
    --billboard-padding-x: 0;
    --billboard-padding-y: 20px;
    --billboard-content-padding-y: 20px;
    --billboard-content-width: unset;

    display: block;

    &:global(._align-right) {
      --billboard-content-overlay-border-width: 8px 0 0;
    }
  }

  &:global(._theme-lightMode) {
    --billboard-content-color: #{$white};
  }

  &:global(._theme-darkMode) {
    --billboard-content-color: #{$black};
  }

  &:global(._appearance-adaLockup) {
    &:global(._theme-lightMode) {
      --billboard-content-overlay-bg: #{rgba($black, 0.8)};
      --promobanner-cta-arrow-color: #{$white};
    }

    &:global(._theme-darkMode) {
      --billboard-content-overlay-bg: #{rgba($white, 0.8)};
    }

    &:global(._overlay-virginRed) {
      --billboard-content-overlay-border: solid #{$vv-red};
    }

    &:global(._overlay-rockstar) {
      --billboard-content-overlay-border: solid #{$brass-cabin};
    }

    &:global(._overlay-uplift) {
      --billboard-content-overlay-border: solid #{$uplift};
    }

    &:global(._overlay-squidInkLight) {
      --billboard-content-overlay-border: solid #{$purple};
    }

    &:global(._overlay-oceanBlue) {
      --billboard-content-overlay-border: solid #{$blue-purple};
    }
  }
}

.picture {
  z-index: 1;
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: 50% 50%;
  border-radius: inherit;
}

.content {
  z-index: 2;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: var(--billboard-content-width);
  color: var(--billboard-content-color);
  padding: var(--billboard-content-padding-y) var(--billboard-content-padding-x);
  background-color: var(--billboard-content-overlay-bg);
  border: var(--billboard-content-overlay-border);
  border-width: var(--billboard-content-overlay-border-width);

  .inner {
    overflow: hidden;
  }

  .eyebrowBox {
    flex: 1;
    margin-bottom: 8px;
  }

  .cta {
    margin-top: 24px;

    button {
      max-width: fit-content;
    }
  }

  .eyebrowBox,
  .cta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    @include font($size: rem(24), $line-height: rem(32), $spacing: rem(-1), $weight: 700);
  }

  .description {
    @include font($size: rem(17), $line-height: rem(24), $weight: 500);
  }

  @include layoutDown(md) {
    .title {
      @include font($size: rem(18), $line-height: rem(24), $spacing: rem(-0.75));
    }

    .description {
      @include font($size: rem(13), $line-height: rem(18));
    }
  }

  @include layoutDown(sm) {
    color: unset;
    padding: unset;
    background-color: unset;
    border: unset;
    border-width: unset;
    min-height: 462px;

    .inner {
      color: var(--billboard-content-color);
      padding: var(--billboard-content-padding-y) var(--billboard-content-padding-x);
      background-color: var(--billboard-content-overlay-bg);
      border: var(--billboard-content-overlay-border);
      border-width: var(--billboard-content-overlay-border-width);
    }

    .eyebrowBox {
      padding-top: var(--billboard-content-padding-y);
      padding-right: var(--billboard-content-padding-x);
      padding-left: var(--billboard-content-padding-x);
    }

    .title {
      @include font($size: rem(20), $line-height: 100%, $spacing: rem(-0.56));
    }

    .description {
      @include font($size: rem(14), $line-height: 100%);

      margin-top: 8px;
    }
  }

  @include layoutDown(xs) {
    min-height: 393px;
  }

  .root:global(._align-right) & {
    .textual {
      width: 100%;
      text-align: right;
    }

    .eyebrowBox,
    .cta {
      align-items: flex-end;
    }
  }
}
