@import '@/_styles/import';
.Grabbable {
  $shadow-color-default: $black-003;
  $shadow-size-default: 12px;

  overflow: scroll;

  @include no-scrollbar();

  &__outer:is(._left, ._right, ._bottom, ._top) {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      z-index: 1;
    }
  }

  &__outer:is(._left, ._top)::before {
    content: '';
    top: 0;
    left: 0;
  }

  &__outer:is(._right, ._bottom)::after {
    content: '';
    right: 0;
    bottom: 0;
  }

  &__outer._left::before {
    bottom: 0;
    width: var(--grabbable-shadow-size, $shadow-size-default);
    background-image: linear-gradient(90deg, var(--grabbable-shadow-color, $shadow-color-default), transparent);
  }

  &__outer._right::after {
    top: 0;
    width: var(--grabbable-shadow-size, $shadow-size-default);
    background-image: linear-gradient(270deg, var(--grabbable-shadow-color, $shadow-color-default), transparent);
  }

  &__outer._top::before {
    right: 0;
    height: var(--grabbable-shadow-size, $shadow-size-default);
    background-image: linear-gradient(180deg, var(--grabbable-shadow-color, $shadow-color-default), transparent);
  }

  &__outer._bottom::after {
    left: 0;
    height: var(--grabbable-shadow-size, $shadow-size-default);
    background-image: linear-gradient(0deg, var(--grabbable-shadow-color, $shadow-color-default), transparent);
  }
}
